/*
 Theme Name: Neher Data Systems
 Description: Neher Data Systems Website 
 Author: Tyler M  Neher
 Author URL:  https://github.com/neherdata/
 Tags: Business , Website
*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  inline-size: auto;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;

}

.App-footer {
  background-color: #282c34;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  padding-bottom: 10%;
}

.Email-link {
  color: white;
}

/* .App.icons-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create two equal columns that sits next to each other */
/* .App.icons-column {
  flex: 50%;
  padding: 0 4px;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}